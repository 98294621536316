import React from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable } from '../components/client/data-table'
import { DialogDemo } from '../components/client/dialogShad'
import { useFOrm } from '../components/useFOrm'
import { Label } from "../components/ui/label"
import { InputShadcn } from '../components/client/InputShadcn'
import { useToast } from "../components/ui/use-toast"
import { SheetShad } from '../components/client/SheetShad'
import SelectShad from '../components/client/SelectShad'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { useEffect } from 'react'
import moment from 'moment'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "../components/ui/dropdown-menu"
import { MoreHorizontal } from "lucide-react"
import { Button } from '../components/ui/button'
import BtnShad from '../components/client/btnShadcn'
import { CheckShad } from '../components/client/CheckShad'



function CouponScreen() {
    const { t } = useTranslation()
    const data = []
    const [promoCode, setPromoCode] = useState([])
    const [isMarketing, setIsMarketing] = useState(false)
    const navigate = useNavigate()
    const [listCoupon, setListCoupon] = useState([])
    const axiosPrivate = useAxiosPrivate()
    const initialValues = {
        code: "",
        description: "",
        discountValue: 0,
        validTo: "",
        voucher: ""

    }
    const columns = [
        // {
        //     accessorKey: "id",
        //     header: "ID coupon",
        // },
        {
            accessorKey: "code",
            header: "Code promo",
            width: 50
        },
        {
            accessorKey: "description",
            header: "Description",
            width: "400px",
            cell: ({ row: { original } }) => {
                return (
                    <div>{original.description}</div>
                )
            }
        },
        {
            accessorKey: "discountValue",
            header: "Montant (USDT)"
        },
        {
            accessorKey: "isActive",
            header: "Status",
            cell: ({ row: { original } }) => {
                if (original.isActive) {
                    return (
                        <span className='text-green-500 font-bold'>En ligne</span>
                    )
                }
                return (
                    <span className='text-red-500 font-bold'>Hors ligne</span>
                )
            }
        },

        {
            accessorKey: "createdAt",
            header: "Créer le",
            cell: ({ row: { original } }) => {
                return (
                    moment(original.createdAt).format("DD-MM-YYYY HH:mm")
                )
            }
        },


        //Add action to edit status credit
        // {
        //     id: "actions",
        //     header: "Actions",
        //     cell: ({ row: { original } }) => {
        //         //const payment = row.original

        //         return (
        //             <DropdownMenu className="h-2 bg-red-500">
        //                 <DropdownMenuTrigger asChild>
        //                     <Button variant="ghost" className="h-4 w-4 p-0">
        //                         <span className="sr-only">Open menu</span>
        //                         <MoreHorizontal className="h-4 w-4" />
        //                     </Button>
        //                 </DropdownMenuTrigger>
        //                 <DropdownMenuContent align="end">
        //                     <DropdownMenuLabel>Actions</DropdownMenuLabel>

        //                     <DropdownMenuSeparator />


        //                     <DropdownMenuItem
        //                         onClick={() => console.log("joo")}>
        //                         Restaurer status
        //                     </DropdownMenuItem>
        //                 </DropdownMenuContent>
        //             </DropdownMenu >
        //         )
        //     },
        // },

    ]
    const { toast } = useToast()
    const validate = (fieldValues = values) => {
        let temp = { ...errors };

        // if ("code" in fieldValues) temp.code = fieldValues.code ? "" : t("error.required");
        if ("code" in fieldValues) {
            temp.code = !fieldValues.code ? t("error.required") : /\s/.test(fieldValues.code) ? "Le texte contient des espaces" : "";
        }

        if ("description" in fieldValues) {
            temp.description = !fieldValues.description ? t("error.required") : fieldValues.description.length > 50 ? "Maximum 50 caractères" : "";
        }
        if ("discountValue" in fieldValues) temp.discountValue = fieldValues.discountValue ? "" : t("error.required");
        // if ("validTo" in fieldValues) temp.validTo = fieldValues.validTo ? "" : t("error.required");
        setErrors({
            ...temp,
        });
        console.log("Temp", temp);

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const { values, setValue, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialValues, true, validate);


    const newVoucherInput = [
        {
            name: "code",
            label: "code promo",
            id: "code",
            defaultValue: "Code promo",
            type: "text"
        },
        {
            name: "description",
            label: "Description",
            id: "desc",
            defaultValue: "Description",
            type: "text"
        },
        {
            name: "discountValue",
            label: "Montant du crédit",
            id: "discount",
            defaultValue: "Montant du crédit",
            type: "number"
        },

    ]
    const voucherToClient = [
        {
            name: "email",
            label: "Email",
            id: "email",
            defaultValue: "email",
            type: "text",
            helpText: "Ajoutez plusieurs emails en les séparant par une virgule "
        }

    ]

    const handleSaveNewVoucher = async () => {
        console.log("iiiiiiiiiii-----------------------------------------");
        resetForm()
        try {
            if (validate()) {
                console.log("YEEEEHHHH");
                const dataToPost = {
                    code: values.code,
                    description: values.description,
                    discountValue: values.discountValue,
                    validTo: values.validTo
                }
                const { data } = await axiosPrivate.post(`/api/coupons`, dataToPost)
                toast({
                    title: `Coupon "${values.code}" créér avec succès  `,
                    description: `Coupon de ${values.discountValue} USDT`,
                })
                console.log("iiiiiii", data);
                setListCoupon(prev => [data, ...prev])
                // window.location.reload()
            }
        } catch (error) {
            console.log("error", error);
            toast({ variant: "destructive", title: error.response.data.msg })
        }
    }
    const handleSaveVoucherClient = async () => {
        console.log("ATOOOooooooooo-----------------------------------------");
        resetForm()
        try {
            const dataToPost = {
                emails: values.email.split(",").map(function (email) {
                    return email.trim();
                }),
                idCoupon: values.voucher
            }
            const { data } = await axiosPrivate.post(`/api/coupons/assign/bulk`, dataToPost)

            console.log("voucher id", dataToPost);
            // set voucher id and all enmail in a state and send this in back
            toast({
                title: `Coupon "${values.code}" ${values.voucher} assigné à  `,
                description: `${JSON.stringify(values.email.split(","))}`,
            })
            console.log("navigate", values);
            // window.location.reload()
            // navigate(`/coupon?sitraka=2`)
            console.log("yes");
        } catch (error) {
            console.log("NOOOOOO");
            toast({
                title: "Assignation de coupon échoué",
                variant: "destructive",
                duration: 30000,
                description: <div>
                    {error.response.data.details.length > 0 ?
                        error.response.data.details.map((item) => (
                            <div className='text-xs flex font-semibold'>
                                {item.email} : {item.error}
                            </div>)) : ""}

                </div>
            })

        }
    }


    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getAllCoupons = async () => {
            try {
                const { data } = await axiosPrivate.get(`/api/coupons`, { signal: controller.signal })
                isMounted && setListCoupon(data)
                setPromoCode(data.map((m) => ({ id: m.id, item: m.code })))
                console.log("BOOOOOO", data, "promoCode", data.map((m) => ({ id: m.id, item: m.code })));

            } catch (error) {
                console.log("error", error);

            }
        }
        getAllCoupons()
    }, [])

    useEffect(() => { }, [

    ])

    return (
        <div className="p-4  gap-3 h-[93vh] ">
            <div className=" bg-white dark:bg-slate-800 gap-y-5 rounded-lg p-4 h-full ">
                <h3 className="font-bold text-xl text-slate-700 dark:text-slate-100">{t(`label.coupon`)}</h3>
                <div className="container mx-auto ">
                    <div className='flex justify-between'>

                        {/* Creation coupons */}
                        <DialogDemo
                            trigger={<BtnShad text={"Créer un crédit"} />}
                            title={"Créer un crédit"}
                            text={"Ajouter un nouveau crédit"}
                            closeComponent={<BtnShad text={t(`button.save`)} onClick={handleSaveNewVoucher} wFull
                                sendHistory={{ action: `Creation Credit de ${values.discountValue} USDT `, who: ` - ` }}
                            />}

                        >
                            <>

                                {newVoucherInput.map((d) => (
                                    <div>
                                        <Label htmlFor="name" className="text-right">
                                            {d?.label}
                                        </Label>
                                        <InputShadcn
                                            id={d?.id}
                                            onChange={handleInputChange}
                                            name={d.name}
                                            value={values[d.name]}
                                            error={errors[d.name]}
                                            label={d.label}
                                            type={d.type}
                                        />

                                    </div>
                                ))}

                                <div className=' my-1'>
                                    <CheckShad
                                        text={"Offre marketing"}
                                        value={isMarketing}
                                        name={"isMarketing"}
                                        onChange={(e) => {
                                            setIsMarketing(e.target.value);
                                            console.log("isMArk", e.target);
                                        }} />
                                </div>

                                {isMarketing &&
                                    <InputShadcn
                                        onChange={handleInputChange}
                                        name={"validTo"}
                                        value={values["validTo"]}
                                        error={errors["validTo"]}
                                        label={"Valide jusqu'à"}
                                        type={"date"}
                                    />
                                }
                            </>

                        </DialogDemo>

                        {/* Assignation des codes promo aux clients */}
                        <SheetShad
                            trigger={<BtnShad text={"Ajouter un crédit aux clients"} />}
                            title="Ajouter un crédit à un client"
                            description="description"

                            closeComponent={<BtnShad onClick={() => handleSaveVoucherClient()} text="Enregistrer" disabled={values.email && values.voucher ? false : true}
                                sendHistory={{ action: `Creation Credit de ${values.discountValue} USDT `, who: ` - ` }}
                            />}
                        // open={open} onOpenChange={setOpen}
                        >
                            {voucherToClient.map((d) => (
                                <div>
                                    <Label htmlFor="name" className="text-right">
                                        {d?.label}
                                    </Label>
                                    <InputShadcn
                                        id={d?.id}
                                        onChange={handleInputChange}
                                        name={d.name}
                                        value={values[d.name]}
                                        error={errors[d.name]}
                                        label={d.label}
                                        type={d.type}
                                        helpText={d.helpText}
                                    />
                                </div>

                            ))}
                            <SelectShad
                                name="voucher"
                                option={promoCode}
                                placeholder="Choisir la code promo"
                                onChange={handleInputChange}
                                label="Choisir la code promo"
                                value={values.voucher} />
                            {/* <BtnShad text={"Submit a voucher to client"} onClick={handleSaveVoucherClient} wFull /> */}
                        </SheetShad>
                    </div>
                    <DataTable columns={columns} data={listCoupon?.length > 0 ? listCoupon : []} />

                </div>
            </div>
        </div>
    )
}

export default CouponScreen
