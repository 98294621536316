import React, { useState, useEffect, useCallback, useRef } from "react";
import useRefreshToken from "../../hooks/useRefreshToken";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import { DataGrid, frFR, GridToolbar } from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import DoneIcon from "@mui/icons-material/Done";
import Controls from "../controls/Controls";
import { toast } from "react-toastify";
//Slices
import { setallUsers, listUserSelector } from "../../features/listUserSlice";
import { addFilter, filterSelector } from "../../features/filterSlice";
import { useDispatch, useSelector } from "react-redux";
import TotalUserFilter from "./TotalUserFilter";
import { addDays } from "date-fns";
import { Dialog, Transition } from "@headlessui/react";
import DialogBox from "../DialogBox";
import { fr } from "date-fns/locale";
import { XIcon, CheckIcon, CheckCircleIcon, XCircleIcon, PauseIcon } from "@heroicons/react/solid";
import moment from "moment";
import Loading from "../Loading";
import { authSelector } from "../../features/authSlice";
import CreateTicket from "../admin/CreateTicket";
//servies
import * as service from "../../utils/service";
import StopSubscription from "../admin/StopSubscription";
import { Icon } from "coinmarketcap-cryptocurrency-icons";
import { useFOrm } from "../useFOrm";
import { countries } from "../../countries";
import Flag from 'react-world-flags';
import { EyeIcon, FingerPrintIcon, IdentificationIcon, PencilAltIcon, PhoneIcon, PlusCircleIcon, ServerIcon, ShieldCheckIcon, SupportIcon } from "@heroicons/react/outline";
import CardHistory from "../Planit/CardHistory"
import IconHoverText from "../IconHoverText";
import AddSub from "../AddSub";
import AddVoucherToUser, { DisplayVoucher } from "../AddVoucherToUser";
import { Ban, Eye, Ticket } from "lucide-react";
import { DialogDemo } from "../client/dialogShad";
import BtnShad from "../client/btnShadcn";
import { TypographyS } from "../Typo";
import { useToast } from "../ui/use-toast";


const ComponentChip2 = ({ params, t }) => {
    if (params.value === t("uStatus.0")) {
        return <span className="rounded-full border-2 border-slate-500 w-fit text-center px-1 text-slate-500 text-xs ">{t("uStatus.0")}</span>;
    } else if (params.value === t("uStatus.1")) {
        return <span className="rounded-full border-2 border-green-500 w-fit text-center px-1 text-green-500 text-xs ">{t("uStatus.1")}</span>;
    } else if (params.value === t("uStatus.2")) {
        return <span className="rounded-full border-2 border-yellow-500 w-fit text-center px-1 text-yellow-500 text-xs ">{t("uStatus.2")}</span>;
    } else if (params.value === t("uStatus.3")) {
        return <span className="rounded-full  border-2 border-orange-500 w-fit text-center px-1 text-orange-500 text-xs ">{t("uStatus.3")}</span>;
    } else if (params.value === t("uStatus.4")) {
        return <span className="rounded-full border-2 border-lime-500 w-fit text-center px-1 text-lime-500 text-xs ">{t("uStatus.4")}</span>;
    } else if (params.value === t("uStatus.5")) {
        return <span className="rounded-full border-2 border-red-500 w-fit text-center px-1 text-red-500 text-xs ">{t("uStatus.5")}</span>;
    } else if (params.value === t("uStatus.6")) {
        return <span className="rounded-full border-2 border-sky-500 w-fit text-center px-1 text-sky-500 text-xs ">{t("uStatus.6")}</span>;
    } else if (params.value === t("uStatus.7")) {
        return <span className="rounded-full border-2 border-amber-500 w-fit text-center px-1 text-amber-500 text-xs ">{t("uStatus.7")}</span>;
    } else if (params.value === t("uStatus.9")) {
        return <span className="rounded-full border-2 border-pink-500 w-fit text-center px-1 text-slate-800 text-xs ">{t("uStatus.9")}</span>;
    } else if (params.value === t("uStatus.10")) {
        return <span className="rounded-full border-2 border-indigo-500 px-1 w-fit text-center text-indigo-500  text-xs ">{t("uStatus.10")}</span>;
    } else if (params.value === t("uStatus.11")) {
        return <span className="rounded-full border-2 border-slate-500 px-1 w-fit text-center text-cyan-600  text-xs ">{t("uStatus.11")}</span>;
    } else if (params.value === t("uStatus.12")) {
        return <span className="rounded-full border-2 border-slate-500 px-1 w-fit text-center text-cyan-600  text-xs ">{t("uStatus.12")}</span>;
    } else if (params.value === t("uStatus.15")) {
        return <span className="rounded-full  border-2 border-amber-500 w-fit text-center px-1 text-amber-500 text-xs ">{t("uStatus.15")}</span>;
    } else if (params.value === t("uStatus.16")) {
        return <span className="rounded-full  border-2 border-cyan-500 w-fit text-center px-1 text-cyan-500 text-xs ">{t("uStatus.16")}</span>;
    } else if (params.value === t("uStatus.16")) {
        return <span className="rounded-full  border-2 border-pink-500 w-fit text-center px-1 text-pink-500 text-xs ">{t("uStatus.18")}</span>;
    }
};

const ComponentChip = ({ params, t }) => {
    const getStatusColor = (value) => {
        const colorMap = {
            0: "slate",
            1: "green",
            2: "yellow",
            3: "orange",
            4: "lime",
            5: "red",
            6: "sky",
            7: "amber",
            9: "pink",
            10: "indigo",
            11: "slate",
            12: "slate",
            15: "amber",
            16: "cyan",
            18: "pink"
        };
        return colorMap[value] || "slate"; // Default to "slate" if color not found
    };

    const status = params;
    const color = getStatusColor(status);
    const style = `rounded-full border-2 border-${color}-500 w-fit text-center px-1 text-${color}-500 text-xs`;

    return <span className={style}>{t(`uStatus.${status}`)}</span>;
};

const pageSize = 10; // Display 10 items per page

const initialValues = {
    platform: "",
    strategy: "normal",
    product: "",
    plan: "",
    duration: "",
    specialPrice: "",
    motifPause: "",
    dateReactivation: ""
};

function Users(props) {
    const { t, fetchAllUser, reset } = props;
    const [users, setUsers] = useState();
    const [search, setSearch] = useState("");
    const [filterUser, setFilterUser] = useState([]);
    const listUser = useSelector(listUserSelector);
    const filters = useSelector(filterSelector);
    const refresh = useRefreshToken();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const dispacth = useDispatch();
    const dateRefInputRef = useRef(null);

    const [isFiltered, setIsFiltered] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [dataSteps, setDataSteps] = useState(false);
    const [emailSelected, setEmailSelected] = useState("");
    const [openTicketModal, setOpenTicketModal] = useState(false);
    const [openStopModal, setOpenStopModal] = useState(false);
    const [uidNewTicket, setUidNewTicket] = useState();
    const [dataUser, setDataUser] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isBtnLoading, setIsBtnLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [isLastPage, setIsLastPage] = useState(false);
    const [ticketIsOpen, setTicketIsOpen] = useState(false);
    const [cardUser, setCardUser] = useState([]);
    const [changeStatusOpen, setChangeStatusOpen] = useState(false);
    const [subscriptionOpen, setSubscriptionOpen] = useState(false);
    const [voucherOpen, setVoucherOpen] = useState(false);
    const [voucherUser, setVoucherUser] = useState(false);
    const [viewVoucher, setViewVoucher] = useState(false);
    const [subscriptionUser, setSubscriptionUser] = useState();
    const [selectedId, setSelectedId] = useState();
    const [userStatus, setUserStatus] = useState(selectedId?.status);
    const [boosterAiOpen, setBoosterAiOpen] = useState(false)
    const [boosterAiLoading, setBoosterAiLoading] = useState(false);
    const [openWaitStop, setOpenWaitStop] = useState(false);
    const [loadingWaitStop, setLoadingWaitStop] = useState(false);
    const [allProduct, setAllProduct] = useState();
    const [inputDateRef, setInputDateRef] = useState('');
    const [changeStatusOpenToPause, setChangeStatusOpenToPause] = useState(false);
    const listStatus = [
        { id: 0, title: t("uStatus.0") },
        { id: 1, title: t("uStatus.1") },
        { id: 2, title: t("uStatus.2") },
        { id: 3, title: t("uStatus.3") },
        { id: 4, title: t("uStatus.4") },
        { id: 5, title: t("uStatus.5") },
        { id: 6, title: t("uStatus.6") },
        { id: 7, title: t("uStatus.7") },
        { id: 8, title: t("uStatus.8") },
        { id: 9, title: t("uStatus.9") },
        { id: 10, title: t("uStatus.10") },
        { id: 11, title: t("uStatus.11") },
        { id: 12, title: t("uStatus.12") },
        { id: 13, title: t("uStatus.13") },
        { id: 14, title: t("uStatus.14") },
        { id: 15, title: t("uStatus.15") },
        { id: 16, title: t("uStatus.16") },
        { id: 18, title: t("uStatus.18") },
    ]
    const [userExist, setUserExist] = useState(false)
    const strategyAi = [
        { id: "normal", title: t(`strategy.cbdt`) },
        { id: "ai", title: t(`strategy.ai`) }
    ]
    //internal component for progress bar
    const Percentage = ({ number, data, email }) => (
        <div
            className="max-w-md w-full  bg-slate-200 rounded text-center relative cursor-pointer"
            onClick={() => {
                setIsOpen(true);
                setDataSteps(data);
                setEmailSelected(email);
            }}
        >
            <div className={`z-9 absolute ${number < 40 ? "bg-red-400" : number >= 40 && number <= 70 ? "bg-orange-400" : "bg-emerald-400"} rounded text-center  h-full`} style={{ width: `${number}%` }} />
            <span className="z-10 relative dark:text-white">{number}%</span>
        </div>
    );
    const { toast } = useToast()

    const validate = (fieldValues = values) => {
        let temp = { ...errors };

        if ("strategy" in fieldValues) temp.strategy = fieldValues.strategy ? "" : t("error.required");
        if ("platform" in fieldValues) temp.platform = fieldValues.platform ? "" : t("error.required");
        setErrors({
            ...temp,
        });

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const { values, setValue, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialValues, true, validate);


    //const MyIndicator = ({ value }) => { value ? (<CheckCircleIcon className='w-4 h-4 text-green-500' />) : (<XCircleIcon />) }

    const MyIndicator = ({ value }) => {
        if (!value) return <XCircleIcon className="w-4 h-4 text-red-500" />;
        return <CheckCircleIcon className="w-4 h-4 text-green-500" />;
    };

    const auth = useSelector(authSelector);

    const checkLastBalance = async (email, uid) => {

        try {
            setIsLoading(true);
            const response = await axiosPrivate.get(`/api/lastbalance/now/${uid}`);
            const totalJSon = JSON.parse(response.data.total);
            toast.success(
                <div className="flex-col text-xs space-y-2">
                    <div>{email}</div>
                    <div className="flex space-x-2 items-center">
                        <div>{totalJSon.usdt}</div> <Icon i="usdt" size={20} />
                    </div>
                    <div className="flex space-x-2 items-center ">
                        <div>{totalJSon.eur}</div> <Icon i="eur" size={20} />
                    </div>
                </div>,
                {
                    // position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                }
            );
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toast.error(`${t("error.checkAPIRO")}`);
        }
    };

    const openUserTicket = async (uid) => {
        setTicketIsOpen(true)

        try {
            const { data } = await axiosPrivate.get(`/api/planit/card/list/${uid}`)
            setCardUser(data)
        } catch (error) {
            console.log(error)
        }
    }

    const handleOpenChangeStatus = (user) => {
        setChangeStatusOpen(true)
        setSelectedId(user)
    }
    const handleOpenChangeStatusToPause = (user) => {
        setChangeStatusOpenToPause(true)
        setSelectedId(user)
    }

    const handleAddAi = (user) => {
        setBoosterAiOpen(true)
        setSelectedId(user)

    }

    const handleOpenWaitStop = (user) => {
        setOpenWaitStop(true)
        setSelectedId(user)

    }

    // Support {decryptCBData(auth.name)}
    const column = [
        { field: "id", headerName: "ID", type: "number", headerClassName: "dark:text-slate-100" },
        //Platform
        {
            field: "platform", headerName: t("label.platform"), type: "text", headerClassName: "dark:text-slate-100",
            valueGetter: (params) => {
                let parameter = params.row.platform;
                let plat = "";
                if (parameter == 1) {
                    plat = "Binance"
                }
                else if (parameter == 2) {
                    plat = "kraken"
                }
                else {
                    plat = null
                }

                return plat
            },

            renderCell: (params) => {
                return (
                    <>
                        {params.row.platform == 1 ? <div className="text-yellow-500 w-fit p-1 rounded-lg font-medium text-xs">BINANCE </div>
                            : params.row.platform == 2 ? <div className="text-purple-500 w-fit p-1 rounded-lg font-medium text-xs">KRAKEN </div>
                                : null}
                        {/* <div>
                            {params.row.strategy == "ai" && <div className="bg-teal-600 text-white font-bold text-[9px] p-1 rounded-full">AI</div>}
                        </div> */}

                    </>
                );
            },
        },
        //Strategy
        {
            field: "strategy", headerName: t("label.strategy"), type: "text", headerClassName: "dark:text-slate-100", width: 70,

            valueGetter: (params) => {
                let parameter = params.row.strategy;
                let strat = "";
                if (parameter == "ai") {
                    strat = t(`strategy.ai`)
                }
                else if (parameter == "normal") {
                    strat = t(`strategy.cbdt`)
                }
                else {
                    strat = null
                }

                return strat
            },

            renderCell: (params) => {
                return (
                    <>
                        {params.row.strategy == "ai" ? <div className="bg-cyan-500 text-white font-bold text-[9px] p-1 rounded-full text-center">{t(`strategy.ai`)}</div>
                            : params.row.strategy == "normal" ? <div className="bg-teal-600 text-white font-bold text-[9px] p-1 rounded-full text-center">{t(`strategy.cbdt`)} </div>
                                : null}

                    </>
                );
            },
        },

        {
            field: "lastName",
            headerName: t("label.lastName"),
            width: 150,
            headerClassName: "dark:text-slate-100",
        },
        {
            field: "firstName",
            headerName: t("label.firstName"),
            width: 150,
            headerClassName: "dark:text-slate-100",
        },
        {
            field: "email",
            headerName: t("label.email"),
            width: 200,
            headerClassName: "dark:text-slate-100",
        },
        {
            field: "country", headerName: "Pays", type: "text", headerClassName: "dark:text-slate-100",
            valueGetter: (params) => {
                let countryName = countries.filter((c) => c.id == params.row.country)
                return countryName.length == 1 ? countryName[0].title : ""
            },

            renderCell: (params) => {
                let countryName = countries.filter((c) => c.id == params.row.country)
                return (
                    // <div> {countryName.length == 1 ? countryName[0].title : "-"} </div>
                    <div className=" flex space-x-1 h-4 w-5">
                        {countryName.length == 1 ? <Flag code={params?.row?.country.toLowerCase()} height={16} /> : "-"}
                        <div className="text-[10px]"> {countryName.length == 1 ? countryName[0].title : ""} </div>
                    </div>

                );
            },
        },
        {
            field: "status",
            headerName: t("label.status"),
            headerClassName: "dark:text-slate-100",

            valueGetter: (params) => {
                let parameter = params.value;
                let cell = "";
                cell = t(`uStatus.${parameter}`);
                return cell;
            },
            renderCell: (params) => {
                return <ComponentChip params={params.row.status} t={t} />;
            },
        },
        // btn : to clone and add other strategy
        {

            field: "toAi",
            headerName: t(`label.strategy`),
            width: 100,
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100",

            renderCell: (cellValues) => {
                return (
                    <div className="flex gap-x-2 w-full justify-center ">
                        <IconHoverText
                            icon={<PlusCircleIcon />}
                            classIcon={"w-5 h-5 text-cyan-600 hover:bg-cyan-700 hover:text-white hover:rounded-lg hover:cursor-pointer"}
                            text={"add AI "}

                            onClick={() => handleAddAi(cellValues.row)}
                        />

                    </div>
                );
            },
        },
        auth?.id == "87e4efd1-376f-4741-8cc7-76b771b05b76" &&
        {
            field: "toActive",
            headerName: "Change status",
            width: 100,
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100",

            renderCell: (cellValues) => {
                return (
                    <div className="flex gap-x-2 w-full justify-center ">
                        <IconHoverText
                            icon={<SupportIcon />}
                            classIcon={"w-5 h-5 text-cyan-600 hover:bg-cyan-700 hover:text-white hover:rounded-lg hover:cursor-pointer"}
                            text={"change to ACTIVE "}
                            onClick={() => handleOpenChangeStatus(cellValues.row)}
                        />

                    </div>
                );
            },
        },


        {
            field: "isSousCompte",
            headerName: t("label.subAccount"),
            headerClassName: "dark:text-slate-100",
            valueGetter: (params) => {
                if (params.row.details) {
                    if (params.row.details.isSousCompte == false) return "Non";
                    return "Oui";
                } else {
                    return null;
                }
            },

            renderCell: (cellValues) => {
                if (cellValues.row.details) {
                    if (cellValues.row.details.isSousCompte == true) {
                        return <CheckIcon className="h-4 w-4 mx-auto text-green-500" />;
                    } else if (cellValues.row.details.isSousCompte == false) {
                        return <XIcon className="h-4 w-4 mx-auto text-red-500" />;
                    }
                } else {
                    return <XIcon className="h-4 w-4 mx-auto text-red-500" />;
                }
            },
        },
        {
            field: "startSubscription",
            headerName: t("label.startSubscription"),
            width: 100,
            type: "date",
            headerClassName: "dark:text-slate-100",
            valueGetter: (params) => {
                if (params.row.details) {
                    const date = moment(params.row.details.startSubscription).utcOffset("+00:00");
                    return moment(date).format("DD/MM/YYYY");
                } else {
                    return null;
                }
            },

            renderCell: (cellValues) => {
                if (cellValues.row.details) {
                    return moment(cellValues.row.details.startSubscription, "DD-MM-YYYY").format("DD/MM/YYYY");
                } else {
                    return null;
                }
            },
        },

        {
            field: "percentage",
            headerName: "Achevé",
            width: 100,
            headerClassName: "dark:text-slate-100",
            headerAlign: "center",
            valueGetter: (params) => {
                if (params.row.steps) {
                    return params.row.steps.percentage;
                } else {
                    return 0;
                }
            },
            renderCell: (cellValues) => {
                if (cellValues.row.steps) {
                    return <Percentage number={cellValues.row.steps.percentage} data={cellValues.row.steps.details} email={cellValues.row.email} />;
                } else {
                    return null;
                }
            },
        },
        {
            field: "addedBy",
            headerName: "Ajouter par",
            width: 100,
            renderCell: (cellValues) => {
                if (cellValues.row.addedBy) {
                    return cellValues.row.addedBy
                } else {
                    return " - ";
                }
            },
        },
        // ICONE CLIENTS
        {
            field: "clients",
            headerName: "Clients",
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100 ",
            width: 150,

            renderCell: (cellValues) => {
                return (
                    <div className="flex gap-x-2 ">
                        {/* Vue Clients */}
                        <IconHoverText
                            icon={<EyeIcon />}
                            classIcon={"w-5 h-5 text-indigo-500 hover:bg-indigo-500 hover:text-white hover:rounded-lg hover:cursor-pointer"}
                            text={"Vue Clients"}
                            onClick={(event) => {
                                navigate(`/dashboard/${cellValues.row.id}`);
                            }}
                        />
                        {/* Modifier client */}
                        <IconHoverText
                            icon={<PencilAltIcon />}
                            classIcon={"w-5 h-5 text-purple-500 hover:bg-purple-500 hover:text-white hover:rounded-lg hover:cursor-pointer"}
                            text={"Modifier"}
                            onClick={(event) => {
                                navigate(`/support/user/${cellValues.row.id}`);
                            }}
                        />
                        {/* Sécurisation */}
                        <IconHoverText
                            icon={<ShieldCheckIcon />}
                            classIcon={"w-5 h-5 text-emerald-500 hover:bg-emerald-500 hover:text-white hover:rounded-lg hover:cursor-pointer"}
                            text={"Sécurisation"}
                            onClick={(event) => {
                                navigate(`/support/secure/${cellValues.row.id}`, {
                                    state: {
                                        fullName: `${cellValues.row.firstName} ${cellValues.row.lastName} `,
                                        email: cellValues.row.email,
                                        status: cellValues.row.status,
                                        strategy: cellValues.row.strategy,
                                        seuil: cellValues.row.strategy == "ai" ? 5000 : 10000
                                    },
                                });
                            }}
                        />
                    </div>
                );
            },
        },

        // icone tickets
        {
            field: "tickets",
            headerName: t(`label.tickets`),
            width: 80,
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100",

            renderCell: (cellValues) => {
                return (
                    <div className="flex gap-x-2 w-full justify-center ">
                        <IconHoverText
                            icon={<EyeIcon />}
                            classIcon={"w-5 h-5 text-indigo-500 hover:bg-indigo-500 hover:text-white hover:rounded-lg hover:cursor-pointer"}
                            text={"voir "}
                            onClick={(event) => {
                                openUserTicket(cellValues.row.id);
                            }}
                        />
                        {auth?.id == "87e4efd1-376f-4741-8cc7-76b771b05b76" && <IconHoverText
                            icon={<PlusCircleIcon />}
                            classIcon={"w-5 h-5 text-emerald-500 hover:bg-emerald-500 hover:text-white hover:rounded-lg hover:cursor-pointer"}
                            text={"créer "}
                            onClick={(event) => {
                                setOpenTicketModal(true);
                                setUidNewTicket(cellValues.row.id);

                            }}
                        />}


                    </div>
                );
            },
        },
        {
            field: "balance",
            headerName: "Balance",
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100",
            renderCell: (cellValues) => {
                return (
                    <Controls.Button
                        xsFont
                        small
                        variant="none"
                        color={"text-primary dark:text-slate-100 hover:text-secondary"}
                        text={"Check"}
                        isLoading={isLoading}
                        onClick={(event) => {
                            checkLastBalance(cellValues.row.email, cellValues.row.id);
                        }}
                    />
                );
            },
        },


    ];

    const columnAdmin = [
        { field: "id", headerName: "ID", type: "number" },
        //Platform
        {
            field: "platform", headerName: t("label.platform"), type: "text", headerClassName: "dark:text-slate-100",
            valueGetter: (params) => {
                let parameter = params.row.platform;
                let plat = "";
                if (parameter == 1) {
                    plat = "Binance"
                }
                else if (parameter == 2) {
                    plat = "kraken"
                }
                else {
                    plat = null
                }

                return plat
            },

            renderCell: (params) => {
                return (
                    <>
                        {params.row.platform == 1 ? <div className="text-yellow-500 w-fit p-1 rounded-lg font-medium text-xs">BINANCE </div>
                            : params.row.platform == 2 ? <div className="text-purple-500 w-fit p-1 rounded-lg font-medium text-xs">KRAKEN </div>
                                : null}
                        {/* <div>
                            {params.row.strategy == "ai" && <div className="bg-teal-600 text-white font-bold text-[9px] p-1 rounded-full">AI</div>}
                        </div> */}

                    </>
                );
            },
        },
        //Strategy
        {
            field: "strategy", headerName: t("label.strategy"), type: "text", headerClassName: "dark:text-slate-100", width: 70,

            valueGetter: (params) => {
                let parameter = params.row.strategy;
                let strat = "";
                if (parameter == "ai") {
                    strat = t(`strategy.ai`)
                }
                else if (parameter == "normal") {
                    strat = t(`strategy.cbdt`)
                }
                else {
                    strat = null
                }

                return strat
            },

            renderCell: (params) => {
                return (
                    <>
                        {params.row.strategy == "ai" ? <div className="bg-cyan-500 text-white font-bold text-[9px] p-1 rounded-full text-center">{t(`strategy.ai`)} </div>
                            : params.row.strategy == "normal" ? <div className="bg-teal-600 text-white font-bold text-[9px] p-1 rounded-full text-center">{t(`strategy.cbdt`)} </div>
                                : null}

                    </>
                );
            },
        },
        { field: "lastName", headerName: t("label.lastName"), headerClassName: "dark:text-slate-100" },
        {
            field: "firstName",
            headerName: t("label.firstName"),
            headerClassName: "dark:text-slate-100",
        },
        { field: "email", headerName: t("label.email"), width: 200, headerClassName: "dark:text-slate-100" },
        {
            field: "country", headerName: "Pays", type: "text", headerClassName: "dark:text-slate-100",
            valueGetter: (params) => {
                let countryName = countries.filter((c) => c.id == params.row.country)
                return countryName.length == 1 ? countryName[0].title : ""
            },

            renderCell: (params) => {
                let countryName = countries.filter((c) => c.id == params.row.country)
                return (
                    // <div> {countryName.length == 1 ? countryName[0].title : "-"} </div>
                    <div className=" flex space-x-1 h-4 w-5">
                        {countryName.length == 1 ? <Flag code={params?.row?.country.toLowerCase()} height={16} /> : "-"}
                        <div className="text-[10px]"> {countryName.length == 1 ? countryName[0].title : ""} </div>
                    </div>

                );
            },
        },
        {
            field: "status",
            headerName: t("label.status"),
            headerClassName: "dark:text-slate-100",

            valueGetter: (params) => {
                let parameter = params.value;
                let cell = "";
                cell = t(`uStatus.${parameter}`);

                return cell;
            },
            renderCell: (params) => {
                return <ComponentChip params={params.row.status} t={t} />;
            },
        },
        {
            field: "isSousCompte",
            headerName: t("label.subAccount"),
            headerClassName: "dark:text-slate-100",

            valueGetter: (params) => {
                if (params.row.details) {
                    if (params.row.details.isSousCompte == false) return "Non";
                    return "Oui";
                } else {
                    return null;
                }
            },

            renderCell: (cellValues) => {
                if (cellValues.row.details) {
                    if (cellValues.row.details.isSousCompte == true) {
                        return <CheckIcon className="h-4 w-4 mx-auto text-green-500" />;
                    } else if (cellValues.row.details.isSousCompte == false) {
                        return <XIcon className="h-4 w-4 mx-auto text-red-500" />;
                    }
                } else {
                    return <XIcon className="h-4 w-4 mx-auto text-red-500" />;
                }
            },
        },
        {
            field: "startSubscription",
            headerName: t("label.startSubscription"),
            width: 150,
            type: "date",
            headerClassName: "dark:text-slate-100",

            valueGetter: (params) => {
                if (params.row.details) {
                    const date = moment(params.row.details.startSubscription).utcOffset("+04:00");
                    return moment(date).format("DD/MM/YYYY");
                } else {
                    return null;
                }
            },

            renderCell: (cellValues) => {
                if (cellValues.row.details) {
                    return moment(cellValues.row.details.startSubscription, "DD-MM-YYYY").format("DD/MM/YYYY");
                } else {
                    return null;
                }
            },
        },
        {
            field: "percentage",
            headerName: "Achevé",
            width: 100,
            headerClassName: "dark:text-slate-100",
            headerAlign: "center",
            valueGetter: (params) => {
                if (params.row.steps) {
                    return params.row.steps.percentage;
                } else {
                    return 0;
                }
            },
            renderCell: (cellValues) => {
                if (cellValues.row.steps) {
                    return <Percentage number={cellValues.row.steps.percentage} data={cellValues.row.steps.details} email={cellValues.row.email} />;
                } else {
                    return null;
                }
            },
        },
        {
            field: "lastBalance",
            headerName: "$ Dernier Rééq",
            width: 100,
            headerClassName: "dark:text-slate-100",
            headerAlign: "center",
            type: "number",
            valueGetter: (params) => {
                if (params.row.lastBalance) {
                    return params.row.lastBalance;
                } else {
                    return 0;
                }
            },
            renderCell: (cellValues) => {
                if (cellValues.row.lastBalance) {
                    return cellValues.row.lastBalance;
                } else {
                    return "-";
                }
            },
        },
        {
            field: "addedBy",
            headerName: "Ajouter par",
            width: 100,
            renderCell: (cellValues) => {
                if (cellValues.row.addedBy) {
                    return cellValues.row.addedBy
                } else {
                    return " - ";
                }
            },
        },
        // ICONE CLIENTS
        {
            field: "clients",
            headerName: "Clients",
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100 ",
            width: 150,

            renderCell: (cellValues) => {
                return (
                    <div className="flex gap-x-2 ">
                        {/* Vue Clients */}
                        <IconHoverText
                            icon={<EyeIcon />}
                            classIcon={"w-5 h-5 text-indigo-500 hover:bg-indigo-500 hover:text-white hover:rounded-lg hover:cursor-pointer"}
                            text={"Vue Clients"}
                            onClick={(event) => {
                                navigate(`/dashboard/${cellValues.row.id}`);
                            }}
                        />
                        {/* Modifier client */}
                        <IconHoverText
                            icon={<PencilAltIcon />}
                            classIcon={"w-5 h-5 text-purple-500 hover:bg-purple-500 hover:text-white hover:rounded-lg hover:cursor-pointer"}
                            text={"Modifier"}
                            onClick={(event) => {
                                navigate(`/support/user/${cellValues.row.id}`);
                            }}
                        />

                    </div>
                );
            },
        },
        // icone tickets
        {
            field: "tickets",
            headerName: t(`label.tickets`),
            width: 80,
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100",

            renderCell: (cellValues) => {
                return (
                    <div className="flex gap-x-2 ">
                        <IconHoverText
                            icon={<EyeIcon />}
                            classIcon={"w-5 h-5 text-indigo-500 hover:bg-indigo-500 hover:text-white hover:rounded-lg hover:cursor-pointer"}
                            text={"voir "}
                            onClick={(event) => {
                                openUserTicket(cellValues.row.id);
                            }}
                        />
                    </div>
                );
            },
        },

        {
            field: "stopSubsiption",
            headerName: "Stop Client",
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100",

            renderCell: (cellValues) => {
                return (
                    <Controls.Button
                        disabled={auth.id == 82 ? true : false}
                        xsFont
                        small
                        variant="none"
                        color={"text-red-500 dark:text-slate-100 hover:text-secondary"}
                        text={"Stop"}
                        onClick={(event) => {
                            setOpenStopModal(true);
                            setUidNewTicket(cellValues.row.id);
                        }}
                    />
                );
            },
        },
    ];

    const columnSuperAdmin = [
        { field: "id", headerName: "ID", type: "number", headerClassName: "dark:text-slate-100", width: 150 },
        //Platform
        {
            field: "platform", headerName: t("label.platform"), type: "text", headerClassName: "dark:text-slate-100",
            valueGetter: (params) => {
                let parameter = params.row.platform;
                let plat = "";
                if (parameter == 1) {
                    plat = "Binance"
                }
                else if (parameter == 2) {
                    plat = "kraken"
                }
                else {
                    plat = null
                }

                return plat
            },

            renderCell: (params) => {
                return (
                    <>
                        {params.row.platform == 1 ? <div className="text-yellow-500 w-fit p-1 rounded-lg font-medium text-xs">BINANCE </div>
                            : params.row.platform == 2 ? <div className="text-purple-500 w-fit p-1 rounded-lg font-medium text-xs">KRAKEN </div>
                                : null}
                        {/* <div>
                            {params.row.strategy == "ai" && <div className="bg-teal-600 text-white font-bold text-[9px] p-1 rounded-full">AI</div>}
                        </div> */}

                    </>
                );
            },
        },
        //Strategy
        {
            field: "strategy", headerName: t("label.strategy"), type: "text", headerClassName: "dark:text-slate-100", width: 70,

            valueGetter: (params) => {
                let parameter = params.row.strategy;
                let strat = "";
                if (parameter == "ai") {
                    strat = t(`strategy.ai`)
                }
                else if (parameter == "normal") {
                    strat = t(`strategy.cbdt`)
                }
                else {
                    strat = null
                }

                return strat
            },

            renderCell: (params) => {
                return (
                    <>
                        {params.row.strategy == "ai" ? <div className="bg-cyan-500 text-white font-bold text-[9px] p-1 rounded-full text-center">{t(`strategy.ai`)} </div>
                            : params.row.strategy == "normal" ? <div className="bg-teal-600 text-white font-bold text-[9px] p-1 rounded-full text-center">{t(`strategy.cbdt`)} </div>
                                : null}

                    </>
                );
            },
        },
        { field: "lastName", headerName: t("label.lastName"), headerClassName: "dark:text-slate-100" },
        {
            field: "firstName",
            headerName: t("label.firstName"),
            headerClassName: "dark:text-slate-100",
        },
        { field: "email", headerName: t("label.email"), width: 200, headerClassName: "dark:text-slate-100" },
        //Add subscription
        {
            field: "subscriptionFees",
            headerName: "Abonnement",
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100",
            renderCell: (cellValues) => {
                return (
                    <div className=" w-full flex justify-center">
                        <IconHoverText
                            icon={<PlusCircleIcon />}
                            classIcon={"w-5 h-5 text-cyan-500 hover:bg-cyan-500 hover:text-white hover:rounded-lg hover:cursor-pointer"}
                            text={"Ajouter"}
                            onClick={(event) => {
                                setSubscriptionOpen(true)
                                setSubscriptionUser(cellValues.row)
                            }}
                        />
                    </div>

                );
            },
        },
        //Add voucher to user
        {
            field: "voucher",
            headerName: "Coupon",
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100",
            renderCell: (cellValues) => {
                return (
                    <div className=" w-full flex justify-center space-x-2">
                        <IconHoverText
                            icon={< Ticket size={20} />}
                            classIcon={" text-cyan-500 hover:bg-cyan-500 hover:text-white hover:rounded-sm hover:cursor-pointer"}
                            text={"Ajouter"}
                            onClick={(event) => {
                                setVoucherOpen(true)
                                setVoucherUser(cellValues.row)
                            }}
                        />
                        <IconHoverText
                            icon={< Eye size={20} />}
                            classIcon={" text-cyan-500 hover:bg-cyan-500 hover:text-white hover:rounded-sm hover:cursor-pointer"}
                            text={"Voir"}
                            onClick={(event) => {
                                setViewVoucher(true)
                                setVoucherUser(cellValues.row)
                            }}
                        />
                    </div>

                );
            },
        },
        {
            field: "country", headerName: "Pays", type: "text", headerClassName: "dark:text-slate-100",
            valueGetter: (params) => {
                let countryName = countries.filter((c) => c.id == params.row.country)
                return countryName.length == 1 ? countryName[0].title : ""
            },

            renderCell: (params) => {
                let countryName = countries.filter((c) => c.id == params.row.country)
                return (
                    // <div> {countryName.length == 1 ? countryName[0].title : "-"} </div>
                    <div className=" flex space-x-1 h-4 w-5">
                        {countryName.length == 1 ? <Flag code={params?.row?.country.toLowerCase()} height={16} /> : "-"}
                        <div className="text-[10px]"> {countryName.length == 1 ? countryName[0].title : ""} </div>
                    </div>

                );
            },
        },
        {
            field: "status",
            headerName: t("label.status"),
            headerClassName: "dark:text-slate-100",

            valueGetter: (params) => {
                let parameter = params.value;
                let cell = "";
                cell = t(`uStatus.${parameter}`);

                return cell;
            },
            renderCell: (params) => {
                return <ComponentChip params={params.row.status} t={t} />;
            },
        },
        {
            field: "isSousCompte",
            headerName: t("label.subAccount"),

            valueGetter: (params) => {
                if (params.row.details) {
                    if (params.row.details.isSousCompte == false) return "Non";
                    return "Oui";
                } else {
                    return null;
                }
            },

            renderCell: (cellValues) => {
                if (cellValues.row.details) {
                    if (cellValues.row.details.isSousCompte == true) {
                        return <CheckIcon className="h-4 w-4 mx-auto text-green-500" />;
                    } else if (cellValues.row.details.isSousCompte == false) {
                        return <XIcon className="h-4 w-4 mx-auto text-red-500" />;
                    }
                } else {
                    return <XIcon className="h-4 w-4 mx-auto text-red-500" />;
                }
            },
        },
        {
            field: "startSubscription",
            headerName: t("label.startSubscription"),
            width: 130,
            type: "date",
            //valueFormatter: params => moment(params?.row.details.startSubscription ,'DD-MM-YYYY').format("DD/MM/YYYY hh:mm A"),
            valueGetter: (params) => {
                if (params.row.details) {
                    const date = new Date(moment(params.row.details.startSubscription, "DD-MM-YYYY").format("YYYY-MM-DD"));
                    return date.toLocaleDateString();
                } else {
                    return null;
                }
            },

            renderCell: (cellValues) => {
                if (cellValues?.row?.details?.startSubscription) {
                    return moment((cellValues?.row?.details?.startSubscription).trim(), "DD-MM-YYYY").format("DD/MM/YYYY");
                } else {
                    return null;
                }
            },
        },
        {
            field: "percentage",
            headerName: "Achevé",
            width: 70,
            headerClassName: "dark:text-slate-100",
            headerAlign: "center",
            valueGetter: (params) => {
                if (params.row.steps) {
                    return params.row.steps.percentage;
                } else {
                    return 0;
                }
            },
            renderCell: (cellValues) => {
                if (cellValues.row.steps) {
                    return <Percentage number={cellValues.row.steps.percentage} data={cellValues.row.steps.details} email={cellValues.row.email} />;
                } else {
                    return null;
                }
            },
        },
        {
            field: "lastBalance",
            headerName: "$ Dernier Rééq",
            width: 100,
            headerClassName: "dark:text-slate-100",
            headerAlign: "center",
            type: "number",
            valueGetter: (params) => {
                if (params.row.lastBalance) {
                    return params.row.lastBalance;
                } else {
                    return 0;
                }
            },
            renderCell: (cellValues) => {
                if (cellValues.row.lastBalance) {
                    return cellValues.row.lastBalance;
                } else {
                    return "-";
                }
            },
        },
        // display pnl
        {
            field: "pnl",
            headerName: "Pnl",
            renderCell: (cellValues) => {
                return (
                    <div>
                        <IconHoverText
                            icon={<PencilAltIcon />}
                            classIcon={"w-5 h-5 text-purple-500 hover:bg-purple-500 hover:text-white hover:rounded-lg hover:cursor-pointer"}
                            text={"Modifier"}
                            onClick={(event) => {
                                const cryptEmail = service.encryptCBData(cellValues.row.email)
                                navigate(`/pnl/${cellValues.row.id}`);
                            }}
                        />
                    </div>
                )
            }
        },
        {
            field: "addedBy",
            headerName: "Ajouter par",
            width: 100,
            renderCell: (cellValues) => {
                if (cellValues.row.addedBy) {
                    return cellValues.row.addedBy
                } else {
                    return " - ";
                }
            },
        },
        // ICONE CLIENTS
        {
            field: "clients",
            headerName: "Clients",
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100 ",
            width: 150,

            renderCell: (cellValues) => {
                return (
                    <div className="flex gap-x-2 ">
                        {/* Vue Clients */}
                        <IconHoverText
                            icon={<EyeIcon />}
                            classIcon={"w-5 h-5 text-indigo-500 hover:bg-indigo-500 hover:text-white hover:rounded-lg hover:cursor-pointer"}
                            text={"Vue Clients"}
                            onClick={(event) => {
                                navigate(`/dashboard/${cellValues.row.id}`);
                            }}
                        />
                        {/* Modifier client */}
                        <IconHoverText
                            icon={<PencilAltIcon />}
                            classIcon={"w-5 h-5 text-purple-500 hover:bg-purple-500 hover:text-white hover:rounded-lg hover:cursor-pointer"}
                            text={"Modifier"}
                            onClick={(event) => {
                                navigate(`/support/user/${cellValues.row.id}`);
                            }}
                        />
                        {/* Sécurisation */}
                        <IconHoverText
                            icon={<ShieldCheckIcon />}
                            classIcon={"w-5 h-5 text-emerald-500 hover:bg-emerald-500 hover:text-white hover:rounded-lg hover:cursor-pointer"}
                            text={"Sécurisation"}
                            onClick={(event) => {
                                navigate(`/support/secure/${cellValues.row.id}`, {
                                    state: {
                                        fullName: `${cellValues.row.firstName} ${cellValues.row.lastName} `,
                                        email: cellValues.row.email,
                                        status: cellValues.row.status,
                                    },
                                });
                            }}
                        />
                    </div>
                );
            },
        },
        // icone tickets
        {
            field: "tickets",
            headerName: t(`label.tickets`),
            width: 80,
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100",

            renderCell: (cellValues) => {
                return (
                    <div className="flex gap-x-2 ">
                        <IconHoverText
                            icon={<EyeIcon />}
                            classIcon={"w-5 h-5 text-indigo-500 hover:bg-indigo-500 hover:text-white hover:rounded-lg hover:cursor-pointer"}
                            text={"voir "}
                            onClick={(event) => {
                                openUserTicket(cellValues.row.id);
                            }}
                        />
                        <IconHoverText
                            icon={<PlusCircleIcon />}
                            classIcon={"w-5 h-5 text-emerald-500 hover:bg-emerald-500 hover:text-white hover:rounded-lg hover:cursor-pointer"}
                            text={"créer "}
                            onClick={(event) => {
                                setOpenTicketModal(true);
                                setUidNewTicket(cellValues.row.id);

                            }}
                        />

                    </div>
                );
            },
        },
        {
            field: "balance",
            headerName: "Balance",
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100",
            renderCell: (cellValues) => {
                return (
                    <Controls.Button
                        xsFont
                        small
                        variant="none"
                        color={"text-primary dark:text-slate-100 hover:text-secondary"}
                        text={"Check"}
                        isLoading={isLoading}
                        onClick={(event) => {
                            checkLastBalance(cellValues.row.email, cellValues.row.id);
                        }}
                    />
                );
            },
        },

        // Pause client

        {
            field: "toActive",
            headerName: "Pause",
            width: 100,
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100",

            renderCell: (cellValues) => {
                return (
                    <div className="flex gap-x-2 w-full justify-center ">
                        <IconHoverText
                            icon={<PauseIcon />}
                            classIcon={"w-5 h-5 text-cyan-600 hover:bg-cyan-700 hover:text-white hover:rounded-lg hover:cursor-pointer"}
                            text={"PAUSE "}
                            onClick={() => handleOpenChangeStatusToPause(cellValues.row)}
                        />

                    </div>
                );
            },
        },

        // Attente stop client

        {
            field: "waitStop",
            headerName: "Attente stop",
            width: 100,
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100",

            renderCell: (cellValues) => {
                return (
                    <div className="flex gap-x-2 w-full justify-center ">
                        <IconHoverText
                            icon={< Ban size={16} />}
                            classIcon={"text-red-600  hover:cursor-pointer"}
                            text={"Attente stop "}
                            onClick={() => handleOpenWaitStop(cellValues.row)}

                        />

                    </div>
                );
            },
        },

        //Stop user
        {
            field: "stopSubsiption",
            headerName: "Stop Client",
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100",

            renderCell: (cellValues) => {
                return (
                    <Controls.Button
                        xsFont
                        small
                        variant="none"
                        color={"text-red-500 dark:text-slate-100 hover:text-secondary"}
                        text={"Stop"}
                        onClick={(event) => {
                            setOpenStopModal(true);
                            setUidNewTicket(cellValues.row.id);
                            setDataUser(cellValues.row)
                            localStorage.setItem("userStatus", cellValues.row.status)

                        }}
                    />
                );
            },
        },
    ];
    const [total, setTotal] = useState(users?.length);

    useEffect(() => {
        getUsers(page);
    }, [page]);

    const getUsers = async (page) => {
        try {
            const response = await axiosPrivate.get(`/api/reporting/users?page=${page}`);
            const formatData = response?.data.map((resp) => ({
                ...resp,
                firstName: service.decryptCBData(resp.firstName),
                lastName: service.decryptCBData(resp.lastName),
                email: service.decryptCBData(resp.email),
            }));
            if (response.data) {
                dispacth(setallUsers(formatData));
            }
            // Check if it's the last page
            setIsLastPage(response.data.length < pageSize);
        } catch (error) {
            if (error?.response?.status == 401) {
                navigate("/login", { state: { from: location }, replace: true });
            }
        }
    };

    function getDatesInRange2(startDate, endDate) {
        let date = moment(startDate, "DD-MM-YYYY");
        const dates = [];
        while (date <= moment(endDate, "DD-MM-YYYY")) {
            dates.push(moment(date).format("DD-MM-YYYY"));
            date = moment(date, "DD-MM-YYYY").add(1, "days");
        }
        return dates;
    }

    const onPageChange = (newPage) => {
        setPage(newPage);
    };
    // Handle page change
    const handlePageChange = (newPage) => {
        if (!isLastPage && newPage > page) {
            getUsers(newPage);
        }
        setPage(newPage);
    };

    const handleSearch = async () => {
        try {
            setIsBtnLoading(true);
            const dataToSearch = { email: search };
            const { data } = await axiosPrivate.post(`/api/reporting/oneUser`, dataToSearch);

            const decryptedArray = data.map(item => {
                for (let key in item) {
                    if (key === "firstName" || key === "lastName" || key === "email" || key === "tel") {
                        item[key] = service.decryptCBData(item[key]);
                    }
                }
                return item;
            });

            setFilterUser(decryptedArray);
            setIsFiltered(true);
            setIsBtnLoading(false);
        } catch (error) {
            console.log(error);
            setIsBtnLoading(false);
            toast.error("error in search");
        }
    };

    useEffect(() => {
        localStorage.setItem("activeTab", "Profil")
    }, [])

    const handleSaveChangeStatus = async (uid) => {
        try {
            // openUserTicket(cellValues.row.id);
            await axiosPrivate.put(`/api/users/${uid}`, {
                status: userStatus,
            });
            toast.success(`status changed to ${userStatus}`)

        } catch (error) {
            console.log(error);
            toast.error('une erreur est survenue')
        }
        setChangeStatusOpen(false)
    }
    const handleSaveStatusPause = async (uid) => {
        try {
            const dataToUpdate = {
                pauseInfo: {
                    motif: values.motifPause,
                    reactivation: values.dateReactivation,
                    date: moment().format("YYYY-MM-DD"),
                    oldStatus: selectedId?.status
                },
                status: 16
            }
            // update info
            await axiosPrivate.put(`/api/users/${uid}`, dataToUpdate);

            //close all ticket
            await axiosPrivate.get(`/api/planit/closeCard/${uid}`)

            //mailerlite
            try {
                const mailerliteresp = await axiosPrivate.get(`/api/mailerlite/unsubscription/${uid}`);
                console.log("Lasa any am MAILERLITE");
                toast.success(
                    mailerliteresp.data.msg,
                    {
                        // position: "top-right",
                        autoClose: false,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
            } catch (error) {
                console.log("mailerlite", error);
            }

            toast.success(`status changed to PAUSE`)

        } catch (error) {
            console.log(error);
            toast.error('une erreur est survenue')
        }
        setChangeStatusOpenToPause(false)
    }

    const handleWaitStop = async (uid) => {
        setLoadingWaitStop(true)
        try {
            const datatoUpdate = {
                status: 15,
                isToBeStopped: true
            }
            await axiosPrivate.put(`/api/users/${uid}`, datatoUpdate);
            console.log("Attente stop");
            const mailerliteresp = await axiosPrivate.get(`/api/mailerlite/unsubscription/${uid}`);
            console.log("Lasa any am MAILERLITE", mailerliteresp);

            toast({
                title: `status changed to ATTENTE STOP `,
                variant: "success"
            })

            // toast.success(`status changed to ATTENTE STOP`)
            setOpenWaitStop(false)

        } catch (error) {
            console.log(error);
            toast.error('une erreur est survenue')
        }
        setLoadingWaitStop(false)
    }

    const handleClone = async (uid) => {
        if (validate()) {
            setBoosterAiLoading(true)
            try {
                const newUser = await axiosPrivate.post(`/api/users/clone`, {
                    uid: uid,
                    ...values

                });
                toast.success(`Sous-compte créer`)

                navigate(`/support/user/${newUser?.data?.newId}`);

            } catch (error) {
                console.log(error);
                if (error.response.status == 400) {

                    toast.error(t(`error.${error.response.data.msgCode}`))
                    setBoosterAiOpen(false)
                    setBoosterAiLoading(false)

                } else if (error.response.status == 409) {
                    setUserExist(true)
                    toast.error(t(`error.${error.response.data.msgCode}`))
                    setBoosterAiLoading(false)

                }

            }
        }
    }

    const handleCloneForce = async (uid) => {
        if (validate()) {
            setBoosterAiLoading(true)
            try {
                const newUser = await axiosPrivate.post(`/api/users/clone?force=1`, {
                    uid: uid,
                    ...values

                });
                toast.success(`Sous-compte créer`)

                navigate(`/support/user/${newUser?.data?.newId}`);

            } catch (error) {
                console.log(error);
                toast.error(t(`error.${error.response.data.msgCode}`))
                setBoosterAiOpen(false)
                setBoosterAiLoading(false)
            }
        }
    }

    const plateformList = [
        { id: 1, title: "Binance" },
        { id: 2, title: "Kraken" },
    ];

    const Binance = [
        { id: 1, title: "Binance" },
    ];

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getAllProduct = async () => {
            try {
                const { data } = await axiosPrivate.get(`/api/products?isActive=1`, {
                    signal: controller.signal,
                })
                // Map over the array and extract only 'id' and 'title'
                const formattedData = data.map((product) => ({
                    id: product.id,
                    title: product.name,
                    plan: product.plan
                }));

                setAllProduct(formattedData);

            } catch (error) {
                console.log(error);
            }
        }
        getAllProduct();
    }, [subscriptionOpen])

    const handleInputDateRef = (event) => {
        setInputDateRef(event.target.value)
    }



    const handleAddSub = async () => {
        try {
            values.pid = values.product
            values.uid = subscriptionUser.id
            const { data } = await axiosPrivate.post(`/api/subscriptions`, values)
            setSubscriptionOpen(false)
            resetForm()
        } catch (error) {

        }
    }

    return (
        <>
            {/* Add voucher to user */}
            <DialogDemo
                isControlled
                isOpen={voucherOpen} setIsOpen={setVoucherOpen}
                title={`Ajouter un coupon au client : ${voucherUser.email}`}
            >

                <AddVoucherToUser user={voucherUser} setIsOpen={setVoucherOpen} isOpen={voucherOpen} />

            </DialogDemo>

            {/* View voucher to user */}
            <DialogDemo
                isControlled
                isOpen={viewVoucher} setIsOpen={setViewVoucher}
                title={`Liste de coupons du client : ${voucherUser.email}`}
            >

                <DisplayVoucher user={voucherUser} setIsOpen={setVoucherOpen} isOpen={viewVoucher} />

            </DialogDemo>


            {/* Add subscription */}
            <DialogBox isOpen={subscriptionOpen} setIsOpen={setSubscriptionOpen} title={"Ajouter abonnement"} customClassName="w-full md:w-1/2 ">
                <div className='flex flex-col space-y-2 justify-center items-center mx-2 md:mx-auto  mt-4 pt-2 px-4   py-4 '>
                    <AddSub subscriptionUser={subscriptionUser} allProduct={allProduct} />
                </div>
            </DialogBox>

            {/* Change status */}
            <DialogBox isOpen={changeStatusOpen} setIsOpen={setChangeStatusOpen} >
                <div className="p-4">

                    <div className="pb-4 ">
                        Changer de status pour <strong>{selectedId && selectedId?.email}</strong>
                    </div>
                    <Controls.Select name="userStat"
                        label={"Change status"}
                        value={userStatus}
                        onChange={(e) => {
                            setUserStatus(e.target.value);
                        }}
                        options={listStatus} />
                    {/* <Controls.Button text="OK" onClick={() => handleSaveChangeStatus(selectedId?.id)} /> */}
                    <BtnShad text={" OK "}
                        onClick={() => handleSaveChangeStatus(selectedId?.id)}
                        sendHistory={{ action: `Change status from ${selectedId?.status} to ${userStatus}  `, who: `${selectedId?.id}` }}
                    />
                </div>
            </DialogBox>

            {/* Pause status */}
            <DialogBox isOpen={changeStatusOpenToPause} setIsOpen={setChangeStatusOpenToPause} title={"Mettre en pause"} >
                <div className="p-4 space-y-4 w-[600px] flex flex-col justify-center items-center">
                    <Controls.Input
                        onChange={handleInputChange}
                        label={"Motifs"}
                        type="text"
                        value={values.motifPause}
                        name="motifPause"
                    />

                    <Controls.Input
                        onChange={handleInputChange}
                        label={"Date de réactivation"}
                        type="date"
                        value={values.dateReactivation}
                        name="dateReactivation"
                    />
                    <div className="pb-4 ">
                        Mettre  le client : <strong>{selectedId && selectedId?.email} en pause</strong>
                    </div>
                    <BtnShad
                        text="Mettre en pause" onClick={() => handleSaveStatusPause(selectedId?.id)}
                        wFull
                        sendHistory={{ action: `client en pause`, who: `${selectedId?.id}` }}
                    />

                    {/* <Controls.Button text="Mettre en pause" onClick={() => handleSaveStatusPause(selectedId?.id)} /> */}
                </div>
            </DialogBox>

            {/* Attente stop */}
            <DialogBox isOpen={openWaitStop} setIsOpen={setOpenWaitStop} title={"Attente stop"} >
                <div className="p-4 space-y-4 w-[600px] flex flex-col justify-center items-center">
                    <TypographyS>Mettre {selectedId?.email} en attente stop</TypographyS>
                    <BtnShad
                        text="Attente stop" onClick={() => handleWaitStop(selectedId?.id)}
                        wFull
                        isLoading={loadingWaitStop}
                        sendHistory={{ action: `Attente stop`, who: `${selectedId?.id}` }}
                    />
                </div>
            </DialogBox>

            {/* Achevé */}
            <DialogBox isOpen={isOpen} setIsOpen={setIsOpen}>
                <div className="flex flex-col gap-y-2">
                    <div className="text-sm text-slate-900 font-bold">{emailSelected && emailSelected}</div>
                    <div>
                        <div className="font-semibold text-md">Documents</div>
                        {/* <div>Source de Renvue {dataSteps && dataSteps?.documents?.sourceOfFund ? (<CheckCircleIcon className='w-4 h-4 text-green-500' />) : (<XCircleIcon />)} </div> */}
                        <div className="shadow-lg p-2">
                            <div className="flex items-center text-sm text-slate-800">Source de Revenue {<MyIndicator value={dataSteps && dataSteps?.documents?.sourceOfFund.complete} />} </div>
                            <div className="flex items-center text-xs text-slate-500">{dataSteps && dataSteps?.documents?.sourceOfFund.comment} </div>
                        </div>
                        <div className="shadow-lg p-2">
                            <div className="flex items-center text-sm text-slate-800">Google Link {<MyIndicator value={dataSteps && dataSteps?.documents?.link.complete} />}</div>
                            <div className="flex items-center text-xs text-slate-500">{dataSteps && dataSteps?.documents?.link.comment} </div>
                        </div>
                    </div>
                    <div className="font-semibold text-md flex items-center">APIS {<MyIndicator value={dataSteps && dataSteps?.apis?.api.complete} />}</div>
                    <div className="font-semibold text-md flex items-center">Binance Memo {<MyIndicator value={dataSteps && dataSteps?.addressBinance?.memo.complete} />}</div>

                    <div>
                        <div className="font-semibold text-md">Capital</div>
                        <div className="shadow-lg p-2">
                            <div className="flex items-center text-sm text-slate-800">
                                {t(`label.initialCapital`)} {<MyIndicator value={dataSteps && dataSteps?.capital?.initial.complete} />}{" "}
                            </div>
                            <div className="flex items-center text-xs text-slate-500">{dataSteps && dataSteps?.capital?.initial.comment} </div>
                        </div>
                        <div className="shadow-lg p-2">
                            <div className="flex items-center text-sm text-slate-800">Capital Reccurent {<MyIndicator value={dataSteps && dataSteps?.capital?.recurring.complete} />}</div>
                            <div className="flex items-center text-xs text-slate-500">{dataSteps && dataSteps?.capital?.recurring.comment} </div>
                        </div>
                    </div>
                </div>
            </DialogBox>

            {/* DB for booster AI */}
            <DialogBox isOpen={boosterAiOpen} setIsOpen={setBoosterAiOpen} title={"Strategie"}>
                <div className="flex flex-col gap-4 p-4 ">
                    <div>
                        Quel stratégie pour <strong>{selectedId && selectedId?.email}</strong> ?
                    </div>
                    <Controls.Select
                        name={"strategy"}
                        label={t(`label.strategy`)}
                        value={values.strategy}
                        onChange={handleInputChange}
                        options={strategyAi}
                        noneValue={"Aucun"}
                        error={errors.strategy} />

                    <Controls.Select
                        name="platform"
                        label={"Plateforme"}
                        value={values.platform}
                        onChange={handleInputChange}
                        options={values.strategy == "ai" ? Binance : plateformList}
                        error={errors.platform}
                        noneValue={"Aucun"}
                    />
                    {userExist && <div className="flex flex-col items-end text-red-500 font-bold align-right">
                        <div>
                            {t(`error.3005`)}
                        </div>
                        <Controls.Button text={"Continuer quand même"} onClick={() => handleCloneForce(selectedId?.id)} isLoading={boosterAiLoading} setIsLoading={setBoosterAiLoading} />

                    </div>}

                    {userExist == false &&
                        <Controls.Button text={`${t(`button.save`)}`} onClick={() => handleClone(selectedId?.id)} isLoading={boosterAiLoading} setIsLoading={setBoosterAiLoading} />
                    }
                </div>
            </DialogBox>

            {/* List ticket for oneUser */}
            <DialogBox isOpen={ticketIsOpen} setIsOpen={setTicketIsOpen}>
                {cardUser[0] ?
                    <>

                        <div className="flex flex-col text-sm mb-4 ml-2 ">
                            <div className="flex items-center space-x-1 text-slate-600">
                                <div><ServerIcon className="w-5 h-5 " /></div>
                                <div className="font-semibold text-slate-600">
                                    {service.decryptCBData(cardUser[0]?.u?.firstName)} {service?.decryptCBData(cardUser[0]?.u?.lastName)}
                                </div>
                            </div>

                            <div className="flex items-center space-x-1 text-slate-600">
                                <div> <IdentificationIcon className="w-4 h-4" /></div>
                                <div> {service.decryptCBData(cardUser[0]?.u?.email)}</div>
                            </div>

                            <div className="flex items-center space-x-1 text-slate-600">
                                <div><PhoneIcon className="w-4 h-4" /></div>
                                <div> {service.decryptCBData(cardUser[0]?.u?.tel)}</div>
                            </div>
                            <div className="flex items-center space-x-1 text-slate-600">
                                <div><FingerPrintIcon className="w-4 h-4 " /></div>
                                <div className="truncate  text-xs">{cardUser[0]?.uid}</div>
                            </div>
                        </div>

                        {cardUser?.map((c, i) => (
                            <div className="my-2">
                                <CardHistory card={c} key={i} />
                            </div>
                        ))}
                    </>
                    :
                    <div className=" m-4 text-xs font-light">
                        Pas de résultat ...
                    </div>
                }


            </DialogBox>

            {/* create tickets for user */}
            <CreateTicket openTicketModal={openTicketModal} setOpenTicketModal={setOpenTicketModal} uid={uidNewTicket} t={t} />

            {/* Stop user */}
            <StopSubscription openStopModal={openStopModal} setOpenStopModal={setOpenStopModal} uid={uidNewTicket} t={t} user={dataUser && dataUser} />
            <div className="flex space-x-2">
                <div className="flex items-center  flex-1">
                    <input
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder={"Recherche..."}
                        type="text"
                        value={search}
                        id="last_name"
                        className="text-xs h-8 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg !focus:ring-blue-500 focus:border-blue-500 block w-full p-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                    {search && (
                        <div
                            className="-ml-5 "
                            onClick={() => {
                                setSearch("");
                                setIsFiltered(false);
                            }}
                        >
                            <XIcon className="w-4 " />
                        </div>
                    )}

                </div>

                <Controls.Button text={t("button.search")} small onClick={() => handleSearch()} xsFont isLoading={isBtnLoading} />
            </div>{" "}
            <div>

            </div>
            <DataGrid
                components={{
                    Toolbar: GridToolbar,
                }}
                componentsProps={{
                    toolbar: {
                        // showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                // componentsProps={{
                //     footer: { total }
                // }}
                //autoPageSize={true}
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "id", sort: "desc" }],
                    },
                }}
                rows={isFiltered ? filterUser : listUser}
                columns={auth.role.includes(process.env.REACT_APP_SUPPORT) ? column : auth.role.includes(process.env.REACT_APP_SUPADMIN) ? columnSuperAdmin : columnAdmin}
                //pagination={true}
                disableColumnSelector
                disableDensitySelector
                getRowClassName={(params) => `text-slate-800 dark:text-slate-100`}
                density="compact"
                className="text-red-500"
                autoPageSize
                page={page}
                onPageChange={handlePageChange}
            />
        </>
    );
}

export default Users;
