import moment from "moment";
import React, { useEffect, useState } from "react";
import Controls from "../controls/Controls";
import DialogBox from "../DialogBox";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { authSelector } from "../../features/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setFollowUp, selectFollowUpCard } from "../../features/followSlice";
import { useFOrm } from "../../components/useFOrm";
import { ExclamationIcon } from "@heroicons/react/outline";
import { CheckCircleIcon, LinkIcon, PencilAltIcon, XCircleIcon } from "@heroicons/react/solid";
import IconHoverText from "../IconHoverText";
import ModifPnl from "../ModifPnl";
import * as apiService from "../../utils/apiService"
import { useNavigate } from "react-router-dom";
import TransactionListUser from "../TransactionListUser";
import SubscriptionScreen from "../../screens/SubscriptionScreen";
import BtnShad from "../client/btnShadcn";

// Initial value of the resiliation form
const initialValues = {
    motif: null,
    comment: null,
    payment: 1,
};

const StopSubscription = (props) => {
    const { t } = props;
    const { openStopModal, setOpenStopModal, uid, user } = props;
    // console.log("User stop", user, uid);
    const [dateEnd, setEnDate] = useState(moment().format("YYYY-MM-DD"));
    const [motif, setMotif] = useState();
    const [pnl, setPNL] = useState(0);
    const auth = useSelector(authSelector);
    const axiosPrivate = useAxiosPrivate();
    const dispacth = useDispatch();
    const ticketsList = useSelector(selectFollowUpCard);
    const [isLoading, setIsLoading] = useState(false);
    const [allSub, setAllSub] = useState()
    const [sumAmountTransaction, setSumAmountTransaction] = useState(0)
    const [step, setStep] = useState(0)
    const [isTestLoading, setIsTestLoading] = useState(false)
    const [testRo, setTestApiRo] = useState(false)
    const [testW, setTestApiW] = useState(false)
    const [testT, setTestApiT] = useState(false)
    const [errorApiRo, setErrorApiRo] = useState("");
    const [oneShot, setOneShot] = useState();
    const [balance, setBalance] = useState()
    const navigate = useNavigate();



    const validate = (fieldValues = values) => {
        let temp = { ...errors };

        if ("motif" in fieldValues) temp.motif = fieldValues.motif == "-" || fieldValues.motif == null || fieldValues.motif == "" ? t("error.required") : "";

        if ("comment" in fieldValues) temp.comment = fieldValues.comment == "" || fieldValues.comment == null || fieldValues.comment == " " ? t("error.required") : "";

        setErrors({
            ...temp,
        });

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const { values, setValue, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialValues, true, validate);

    // Fetch all motif for stop subscription
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getMotifTypes = async () => {
            try {
                const response = await axiosPrivate.get(`/api/motif/category/Résiliation`, {
                    signal: controller.signal,
                });

                let motifTypes = response.data.map((t) => ({
                    title: t.motif,
                    id: t.id,
                }));
                isMounted && setMotif(motifTypes);
            } catch (error) {
                console.log("Error in getMotifTypes", error);
            }
        };

        getMotifTypes();

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, []);


    useEffect(() => {
        setStep(0)
    }, [openStopModal])

    const getPnl = async () => {
        let isMounted = true;
        const controller = new AbortController();

        try {
            const { data } = await axiosPrivate.get(`/api/pnl/findBy/${uid}`, {
                signal: controller.signal,
            });

            // console.log("DATA ONL", data);
            const sumTotalFivePercent = data.reduce((sum, entry) => {
                const totalFivePercentObject = JSON.parse(entry.totalFivePercent);
                const totalFivePercentValue = totalFivePercentObject.usdt; // Assuming you want the "usdt" value
                return sum + totalFivePercentValue;
            }, 0);

            // console.log("Sum of totalFivePercent: ", sumTotalFivePercent);

            setPNL(Number(sumTotalFivePercent.toFixed(2)))

        } catch (error) {
            console.log(error);
        }
    }

    const handleGetAllSub = async () => {
        try {
            // console.log("PNL ONE SHOT");
            const { data } = await axiosPrivate.get(`/api/subscriptions?isActive=1&uid=${uid}&transactions=2`)
            const amountsArray = data?.rows?.map(item =>
                item.transactions.map(transaction => parseFloat(transaction.amount))
            );

            const totalAmount = amountsArray.reduce((accumulator, current) =>
                accumulator + (Array.isArray(current) ? current.reduce((a, c) => a + c, 0) : 0), 0
            );

            setAllSub(data.rows)
            setSumAmountTransaction(totalAmount)
            // console.log("ISACTIVE", totalAmount, data.rows);


            const response = await axiosPrivate.get(`/api/subscriptions?isActive=1&uid=${uid}&plan=oneShot`)
            let totalOneShot = 0
            if (response.data.count > 0) {
                for (let sub of response.data.rows) {
                    const oneShotAmount = Number(sub?.specialPrice)
                    totalOneShot += oneShotAmount
                }
                // console.log("oneShot", totalOneShot);
            }
            setOneShot(totalOneShot)

        } catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        getPnl()
        handleGetAllSub()
    }, [uid])

    useEffect(() => {
        if (sumAmountTransaction == 0 && pnl == 0) {
            setStep(0)
        }
    }, [sumAmountTransaction, pnl])

    const total = sumAmountTransaction + pnl + oneShot;
    const handleSubmit = async () => {
        try {
            if (validate()) {
                setIsLoading(true);
                //update endSubscription, motif endSubscription and comment endSubscription
                await axiosPrivate.put(`/api/details/${uid}`, {
                    endSubscription: moment(dateEnd, "YYYY-MM-DD").format("DD-MM-YYYY"),
                    endSubscriptionMotif: values.motif,
                    endSubscriptionComment: values.comment,
                    apiKeyRo: null,
                    secureKeyRo: null,
                    apiKeyTransfer: null,
                    secureKeyTransfer: null,
                    apiKeyTrading: null,
                    secureKeyTrading: null,
                });

                await axiosPrivate.put(`/api/users/${uid}`, {
                    status: 0,
                    by_followup: auth.name,
                    // email: Date.now(),
                    firstName: "CB",
                    lastName: "user",
                    tel: null,
                    password: null,
                    refresh_token: null,
                    otpParams: null,
                    tfa: false,
                });



                await axiosPrivate.get(`/api/planit/closeCard/${uid}`)
                toast.success(`Client ${uid} Stop`,
                    {
                        // position: "top-right",
                        autoClose: false,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
                try {
                    const mailerliteresp = await axiosPrivate.get(`/api/mailerlite/unsubscription/${uid}`);
                    console.log("Lasa any am MAILERLITE");
                    toast.success(
                        mailerliteresp.data.msg,
                        {
                            // position: "top-right",
                            autoClose: false,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                } catch (error) {
                    console.log("mailerlite", error);
                }

                setOpenStopModal(false);
                resetForm();
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error(`Error try again`);
        }
    };

    const handleTestRo = async () => {
        try {
            setIsTestLoading(true);
            let { statusApi, response } = await apiService.testApi("ro", uid)
            setTestApiRo(statusApi);


            if (statusApi) {
                console.log("TEST T zao");

                const responseT = await apiService.testApi("t", uid)
                const statusApiT = responseT.statusApi
                setTestApiT(statusApiT);

                if (statusApiT) {
                    console.log("TEST W zao");

                    const responseW = await apiService.testApi("w", uid)
                    const statusApiW = responseW.statusApi
                    setTestApiW(statusApiW);

                    if (statusApi && statusApiT && statusApiW) {
                        const { data } = await axiosPrivate.get(`/api/lastbalance/now/${uid}`);
                        const balance = JSON.parse(data?.total)
                        // console.log("CHECK", balance.usdt);
                        setBalance(balance?.usdt)
                        setStep(11)
                        console.log("Step 11 : API Ok => client OK");
                    }
                    else {
                        setStep(121)
                        console.log("Step 121 - CHANGE STATUS BEFORE CREATE TICKET");
                    }
                }

            }


        } catch (error) {
            setTestApiRo(false)
            setTestApiW(false)
            setTestApiT(false)
            setStep(121)

            setErrorApiRo(JSON.stringify(error.response.data));
            console.log("Error status Api", error.response.data);
        }
        setIsTestLoading(false);
    };

    const handleClientOK = async () => {
        try {
            // openUserTicket(cellValues.row.id);
            const datatoUpdate = {
                status: 6,
                isToBeStopped: true
            }
            await axiosPrivate.put(`/api/users/${uid}`, datatoUpdate);
            // console.log("NIOVA 2 ehhh", datatoUpdate);

            toast.success(`Client à rééquilibré et récupération des PNL restant `)
            setOpenStopModal(false)
            setStep(0)
        } catch (error) {
            console.log(error);
            toast.error('une erreur est survenue')
        }
    }

    const handleCreateTicket = async () => {

        try {
            const newticket = {
                uid: uid,
                motif: 17,
                step: 0,
                by: auth.name,
                status: false,
                commentMotif: "Client en Stop",
            };

            const resp = await axiosPrivate.post(`/api/ticket/create?pb=stop`, newticket);

            navigate(`/board/4aa03c5f-2b8a-11ee-99ee-00155d821527`)
            // console.log("PPPPPP", newticket);

            if (!resp) throw "Errow While creating tickets";
            toast.success("Ticket creation successfully");
            // console.log(newticket);

        } catch (error) {
            toast.error(error);
        }

    }

    useEffect(() => {
        setStep(0)
        if (openStopModal == false) {
            localStorage.removeItem("userStatus")
        }
    }, [openStopModal])

    // useEffect(() => {
    //     let isMounted = true;
    //     const controller = new AbortController();

    //     const handleGetAllSub = async () => {
    //         try {
    //             const { data } = await axiosPrivate.get(`/api/subscriptions`, {
    //                 signal: controller.signal,
    //             })
    //             setAllSub(data.rows)
    //             console.log("ROWS", data.rows);
    //             const oneSubFilter = data.rows.find((d) => d.uid == uid)
    //             console.log("oneSUb STOP", oneSubFilter);
    //             setOneSub(oneSubFilter)
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     }
    //     const handleOpenTransaction = async () => {
    //         try {

    //             // setOpenTransaction(true)
    //             const { data } = await axiosPrivate.get(`api/transactions?uid=${uid}`)
    //             setListTransaction(data)
    //             console.log("LIST TRANS", data);

    //         } catch (error) {
    //             console.log(error);
    //         }
    //     }
    //     handleOpenTransaction()
    //     handleGetAllSub();
    // }, [step == 21])

    const handleChangeStatus = async (step) => {
        try {
            // openUserTicket(cellValues.row.id);
            const datatoUpdate = {
                status: 15,
                isToBeStopped: true
            }
            await axiosPrivate.put(`/api/users/${uid}`, datatoUpdate);
            console.log("NIOVA 15 ehhh", datatoUpdate);

            const mailerliteresp = await axiosPrivate.get(`/api/mailerlite/unsubscription/${uid}`);
            console.log("Lasa any am MAILERLITE");
            toast.success(
                mailerliteresp.data.msg,
                {
                    // position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            );
            toast.success(`Client waitting for stop`)
            // setOpenStopModal(false)
            console.log("step", step);
            if (step == 121) {
                setStep(12)
            } else if (step == 3) {
                setStep(31)
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response.data.msg)
        }

    }


    const testStop = () => {
        console.log("test Stop");
    }
    return (
        <DialogBox isOpen={openStopModal} setIsOpen={setOpenStopModal} customClassName="max-w-[700px] py-4 px-10">
            {step == 0 &&
                <div className="flex flex-col gap-y-3">
                    <div className="text-sm pb-4">
                        <ExclamationIcon className="w-20 text-red-500 mx-auto" />
                        <div className="text-sm text-center">
                            Vous êtes sur le point de résilier un client. <br />
                            Cela entraine la suppression de toutes ses données seront supprimées, y compris son adresse e-mail, son nom complet, ses APIs, et autres informations associées.
                        </div>

                        {(pnl > 0 || sumAmountTransaction > 0 || oneShot > 0) ?
                            <div className="flex flex-col space-y-3">
                                <div className="font-bold bg-red-100 px-2 rounded-md w-fit mx-auto">
                                    {pnl > 0 && <div className="flex space-x-1">

                                        <div>
                                            PNL non payé(s) : {pnl} usdt
                                        </div>
                                        {/* <IconHoverText
                                            icon={<PencilAltIcon />}
                                            classIcon={"w-5 h-5 text-cyan-600 hover:bg-cyan-500 hover:text-white hover:rounded-lg hover:cursor-pointer"}
                                            text={"Modifier PNL "}

                                        // onClick={() => handleAddAi(cellValues.row)}
                                        /> */}
                                    </div>}


                                    {allSub?.length > 0 && <div className="flex space-x-1">

                                        <div>
                                            Transaction non payé(s) : {sumAmountTransaction} usdt
                                        </div>

                                    </div>}
                                    {oneShot > 0 &&
                                        <div>
                                            one Shot : {oneShot} usdt
                                        </div>}
                                    <div>
                                        Total = {pnl && sumAmountTransaction + oneShot ? (pnl + sumAmountTransaction + oneShot).toFixed(2) : pnl && sumAmountTransaction ? (pnl + sumAmountTransaction).toFixed(2) : pnl && !sumAmountTransaction ? pnl : !pnl && sumAmountTransaction ? sumAmountTransaction : oneShot ? oneShot : null} usdt
                                    </div>
                                </div>
                                {total < 12 ? <div className="w-full bg-yellow-200 text-center text-xs font-bold"> {">>"} La récupération par rééquilibrage n'est pas envisageable, car le montant total à payer est inférieur à 12 {"<<"}</div> : ""}
                                <div className="flex justify-center text-sm ">
                                    <Controls.RadioGroup
                                        newline
                                        items={
                                            total > 12 ? ([
                                                { id: 1, title: "À encaisser par Crypto (rééquilibrage)" },
                                                { id: 2, title: "Déja encaissé (Carte bancaire, virement, autres) " },
                                                { id: 3, title: "A encaissé (Carte bancaire, virement, autres) " },
                                                { id: 4, title: "Récupération impossible " },
                                            ]) : ([
                                                // { id: 1, title: "À encaisser par Crypto (rééquilibrage)" },
                                                { id: 2, title: "Déja encaissé (Carte bancaire, virement, autres) " },
                                                { id: 3, title: "A encaissé (Carte bancaire, virement, autres) " },
                                                { id: 4, title: "Récupération impossible  " },

                                            ])
                                        }
                                        key={""}
                                        label={"Moyen de récupération :"}
                                        value={values.payment}
                                        onChange={handleInputChange}
                                        name={"payment"}
                                    />
                                </div>
                                <div>
                                    <Controls.Button text={t(`button.next`)}
                                        onClick={() => {
                                            if (values.payment == 1) {
                                                console.log("Step 1");
                                                setStep(1)
                                            } else if (values.payment == 2) {
                                                console.log("Step 2 : Paiement par carte bancaire et modif status");
                                                setStep(2)
                                            } else if (values.payment == 3) {
                                                console.log("Step 3 : Waitting stop");
                                                setStep(3)
                                            }
                                            else if (values.payment == 4) {
                                                console.log("Step 4 : Récup impossible");
                                                // window.open(`/transactionSub/${uid}`, '_blank');
                                                setStep(2)
                                            }
                                            // console.log("Paiment par : ", values.payment);
                                        }} isLoading={isLoading} widthFull />
                                </div>

                            </div>
                            :
                            <>
                                <Controls.Input label={t(`label.unsuscribeDate`)} type="date" onChange={(e) => setEnDate(e.target.value)} value={dateEnd} />

                                <Controls.Select name={"motif"} label={t(`label.motif`)} value={values.motif || ""} onChange={handleInputChange} options={motif} error={errors.motif} noneValue={"-"} />

                                <Controls.Input type="text" name="comment" label={t(`label.userArgument`)} value={values.comment || ""} onChange={handleInputChange} error={errors.comment} />
                                {/* <Controls.Button color={"text-white bg-red-500 dark:text-slate-100 hover:bg-red-600"} text={"STOP"} onClick={handleSubmit} isLoading={isLoading} widthFull /> */}
                                <BtnShad text={"STOP"}
                                    onClick={handleSubmit}
                                    isLoading={isLoading}
                                    variant="destructive"
                                    wFull
                                    sendHistory={{ action: `Client STOP - id motif : ${values.motif}`, who: `${user?.id}` }}
                                />
                            </>
                        }



                    </div>




                </div>
            }

            {/* 1 = Paiement par crypto & Test API */}
            {step == 1 &&
                <div className="flex flex-col items-center space-y-2 text-sm ">
                    <div className="font-bold"> Test API et check balance </div>
                    <div>Avant de procéder au rééquilibrage, il est nécessaire de tester tous les APIs du client et s'assurer qu'il y a le montant suffisant sur son compte.</div>
                    <div >
                        <Controls.Button text={"Test APIs et check balance"} onClick={handleTestRo} isLoading={isTestLoading} small />
                    </div>
                </div>
            }

            {/* 11 = API Ok => client OK */}
            {step == 11 &&
                <div className="text-sm flex flex-col items-center space-y-2">
                    <div className="font-bold">API OK</div>
                    {/* <br /> <span className="text-[9px] text-blue-400">Client OK + userStop : true</span> */}
                    {balance > (total + 20) ?
                        <div className="flex flex-col space-y-2  ">
                            <div className="text-left ">
                                - API : OK
                                - Balance suffisant
                            </div>
                            <div> Le client peut être rééquilibré et peut transférer les PNL et/ou subscriptions restant.</div>
                            <div className="bg-green-200 w-fit px-2 rounded">Balance = <b>{balance} usdt</b></div>
                            <div>Voulez-vous rééquilibré ce client afin de récupérer les PNL et/ou subscriptions restant demain (sauf si 29, 30, 31, et 1er du mois) ?  </div>
                            <div className="flex space-x-2 justify-center">

                                <Controls.Button text={"Non"}
                                    color="bg-red-500 text-white"
                                    small
                                    onClick={() => {
                                        setOpenStopModal(false)
                                        setStep(0)
                                        // console.log("Noooon");
                                        toast.error("ACTION ANNULÉ")
                                    }}
                                    isLoading={isTestLoading} />
                                <Controls.Button text={"Oui"} onClick={handleClientOK} isLoading={isTestLoading} small />

                            </div>
                        </div> :

                        <div className="flex flex-col space-y-2 items-center ">
                            <div className="bg-orange-200 w-fit px-2 rounded">Balance = <b>{balance} usdt</b></div>
                            <div className="flex space-x-2 justify-center">

                                - APIs : OK <br />
                                - Balance insuffisant
                            </div>
                            <div>Voulez-vous mettre ce client en attente stop et récupérer le montant via carte bancaire ?  </div>

                            <div className="flex space-x-2 justify-center">
                                <Controls.Button text={"Changer status"} onClick={handleChangeStatus} isLoading={isTestLoading} small />

                                <Controls.Button text={t(`button.cancel`)}
                                    color="bg-red-500 text-white"
                                    small
                                    onClick={() => {
                                        setOpenStopModal(false)
                                        setStep(0)
                                        // console.log("Noooon");
                                        toast.error("ACTION ANNULÉ")
                                    }}
                                    isLoading={isTestLoading} />

                            </div>

                        </div>}


                </div>
                }

            {/* 121 = Change status before create ticket */}

            {step == 121 &&
                <div className="flex flex-col items-center space-y-2 text-sm ">
                    <div className="font-bold text-red-500 flex justify-center"> !!  Les Apis ne sont pas tous fonctionnels  </div>
                    <div> Changer status en Attente stop</div>
                    <div> </div>
                    <div className="flex space-x-2 justify-center">
                        {/* <Controls.Button text={t(`button.cancel`)}
                            color="bg-red-500 text-white"
                            small
                            onClick={() => {
                                setOpenStopModal(false)
                                setStep(0)
                                // console.log("Noooon");
                                toast.error("ACTION ANNULÉ")
                            }}
                            isLoading={isTestLoading} /> */}

                        <BtnShad text={t(`button.cancel`)}
                            variant="destructive"
                            onClick={() => {
                                setOpenStopModal(false)
                                setStep(0)
                                // console.log("Noooon");
                                toast.error("ACTION ANNULÉ")
                            }}
                            size="sm"
                            isLoading={isTestLoading}
                        />

                        {/* <Controls.Button text={"Changer status"} onClick={() => handleChangeStatus(121)} isLoading={isTestLoading} small /> */}
                        <BtnShad text={"Changer status"}
                            onClick={() => handleChangeStatus(121)}
                            size="sm"
                            isLoading={isTestLoading}
                            sendHistory={{ action: `Client Attente STOP `, who: `${user?.id}` }}

                        />
                    </div>
                </div>
                }

            {/* 12 = API not OK => create ticket */}
            {step == 12 &&
                <div className="text-sm">
                    <div>
                        <div className="font-bold text-red-500 flex justify-center">

                            !!  Les Apis ne sont pas tous fonctionnels
                        </div>

                        <div className="flex flex-col space-y-2 items-center ">
                            <div>Souhaitez-vous créer un ticket afin de regler ce problème d'API  ?  </div>
                            <div className="flex space-x-2 justify-center">

                                <Controls.Button text={"Oui"} onClick={handleCreateTicket} isLoading={isTestLoading} small />
                                <Controls.Button text={"Non"}
                                    small
                                    onClick={() => {
                                        setOpenStopModal(false)
                                        console.log("Noooon");
                                    }}
                                    isLoading={isTestLoading} />
                            </div>
                        </div>

                    </div>
                </div>
            }

            {/* 2 = Paiement par carte bancaire et modif status */}
            {step == 2 &&
                <div className="flex flex-col space-y-2 text-sm items-center">
                    {/* <div className="font-bold mb-2">Modification status PNL  </div>
                    <div><ModifPnl uid={uid} setStep={setStep} step={step} sumAmountTransaction={sumAmountTransaction} /></div> */}
                    <div className="font-bold"> Modification status </div>
                    <div className="text-center">
                        La modification du status des PNL et transaction non payés est nécessaire avant de procéder à l'annulation du compte client
                    </div>
                    {pnl > 0 && <div className="flex space-x-1 ">

                        <div>
                            PNL non payé(s) à modifier : <strong> {pnl} usdt</strong>
                        </div>
                        <IconHoverText
                            icon={<PencilAltIcon />}
                            classIcon={"w-5 h-5 text-cyan-600 hover:bg-cyan-500 hover:text-white hover:rounded-lg hover:cursor-pointer"}
                            text={"Modifier PNL "}
                            onClick={() => {
                                window.open(`/transactionSub/${uid}`, '_blank');
                            }}

                        />
                    </div>}

                    {allSub?.length > 0 && <div className="flex space-x-1">

                        <div>
                            Transaction non payé(s) : <strong>{sumAmountTransaction} usdt</strong>
                        </div>
                        <IconHoverText
                            icon={<PencilAltIcon />}
                            classIcon={"w-5 h-5 text-cyan-600 hover:bg-cyan-500 hover:text-white hover:rounded-lg hover:cursor-pointer"}
                            text={"Modifier transaction "}

                            onClick={() => {
                                window.open(`/transactionSub/${uid}`, '_blank');
                            }} />
                    </div>}
                    <div className="flex space-x-2 justify-center">
                        {/* <Controls.Button text={"Attente stop"} onClick={handleChangeStatus} isLoading={isTestLoading} small /> */}

                        <Controls.Button text={t(`button.refresh`)} onClick={() => {
                            setPNL(0)
                            console.log("pnl", pnl);
                            getPnl()
                            handleGetAllSub()
                            console.log("pnl GET", pnl);
                        }} isLoading={isTestLoading} small />
                    </div>




                </div>

            }

            {/* 21 = Modification status transaction */}
            {step == 21 &&
                <div className=" flex flex-col space-y-2 text-sm">
                    <div className="w-[700px] font-bold mb-2 ">Modification status transaction</div>

                    {/* <TransactionListUser oneSub={oneSub} uid={uid} /> */}

                    {/* <SubscriptionScreen uidStop={uid} toStop={1} /> */}
                    <Controls.Button text={"Confirmer"} onClick={() => {
                        console.log("alefa stop amzay");
                        setStep(22)
                    }} isLoading={isTestLoading} small />

                </div>
            }

            {/* 22 = STOP */}
            {step == 22 &&
                <div className="flex flex-col gap-y-3">
                    <>
                        <ExclamationIcon className="w-20 text-red-500 mx-auto" />
                        <div className="text-sm text-center">
                            Vous êtes sur le point de résilier un client. <br />
                            Cela entraine la suppression de toutes ses données seront supprimées, y compris son adresse e-mail, son nom complet, ses APIs, et autres informations associées.
                        </div>
                        <Controls.Input label={t(`label.unsuscribeDate`)} type="date" onChange={(e) => setEnDate(e.target.value)} value={dateEnd} />

                        <Controls.Select name={"motif"} label={t(`label.motif`)} value={values.motif || ""} onChange={handleInputChange} options={motif} error={errors.motif} noneValue={"-"} />

                        <Controls.Input type="text" name="comment" label={t(`label.userArgument`)} value={values.comment || ""} onChange={handleInputChange} error={errors.comment} />
                        <Controls.Button color={"text-white bg-red-500 dark:text-slate-100 hover:bg-red-600"} text={"STOP"} onClick={handleSubmit} isLoading={isLoading} widthFull />
                    </>
                </div>
            }

            {/* 3 = Waitting stop */}
            {step == 3 &&
                <div className="flex flex-col items-center space-y-2 text-sm ">
                    <div className="font-bold"> Attente stop</div>
                    <div> Changer status en Attente stop</div>
                    <div> </div>
                    <div className="flex space-x-2 justify-center">
                        {/* <Controls.Button text={t(`button.cancel`)}
                            color="bg-red-500 text-white"
                            small
                            onClick={() => {
                                setOpenStopModal(false)
                                setStep(0)
                                // console.log("Noooon");
                                toast.error("ACTION ANNULÉ")
                            }}
                            isLoading={isTestLoading} /> */}

                        <BtnShad text={t(`button.cancel`)}
                            variant="destructive"
                            onClick={() => {
                                setOpenStopModal(false)
                                setStep(0)
                                // console.log("Noooon");
                                toast.error("ACTION ANNULÉ")
                            }}
                            size="sm"
                            isLoading={isTestLoading}

                        />
                        {/* <Controls.Button text={"Changer status"} onClick={() => handleChangeStatus(3)} isLoading={isTestLoading} small /> */}
                        <BtnShad text={"Changer status"}
                            onClick={() => handleChangeStatus(3)}
                            size="sm"
                            isLoading={isTestLoading}
                            sendHistory={{ action: `Client Attente STOP `, who: `${user?.id}` }}

                        />
                    </div>
                </div>}

            {/* 3 = Waitting stop */}
            {step == 31 &&
                <div className="flex flex-col items-center space-y-2 text-sm ">
                    <div className="font-bold">Status modifié</div>
                    <div> Status modifié en <b>Attente stop</b> avec succès. <br /> Veuillez finaliser le stop client une fois les montant encaissés.</div>
                    <div> </div>

                </div>}

            {/* 4 = Récup impossible */}
            {step == 4 &&
                <div className="flex flex-col items-center space-y-2 text-sm ">
                    <div className="font-bold">Récupération impossible </div>
                    <div> </div>

                </div>}


        </DialogBox>
    );
};

export default StopSubscription;
